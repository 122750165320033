import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import service from './service';
import './global.css';
import './normalize.css';
import { Flex, Form,Alert } from 'antd';
import { Button, Modal, notification } from 'antd';
import { Input } from 'antd';
import { Spin,Select,Space  } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;


function Home() {
  const [modal, contextHolder] = Modal.useModal();

  const countDown = () => {
    let secondsToGo = 10;
  
    const instance = Modal.success({
      title: 'Payment With Stripe',
      centered: true,
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <p style={{ marginTop: 16 }}>Please capture your cart before {secondsToGo} seconds.</p>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: '#1890ff' }} spin />} />
        </div>
      ),
      icon: null,
    });
  
    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{ marginTop: 16 }}>Please capture your cart before {secondsToGo} seconds.</p>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: '#1890ff' }} spin />} />
          </div>
        ),
      });
    }, 1000);
  
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
    setLoading(true);
  };


  const [api,contextHolder2] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    

    const notification = api[type]({
      message: 'Transaction Success',
      description: 'Thank you dear client.',
      duration: 0,
    });
   
  };
  

  const openNotification = (type) => {
    api[type]({
      message: 'Operation has started',
      placement:'top',
      duration: 3,
    });
  };

  const openNotificationErr = (type,message) => {
    api[type]({
      message: message,
      placement:'top',
      duration: 5,
    });
  };

  const [readersList, setReadersList] = useState(null);
  const [firstreader, setfirstReader] = useState(null);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingbtn, setLoadingbtn] = useState(false);

  const [readerId, setReaderId] = useState(null);
  const [reader, setReader] = useState(null);
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState("GBP");
  const [name, setName] = useState('');
  const [descr, setDescr] = useState('');

  const [paymentIntent, setPaymentIntent] = useState(null);
  const [ip, setIp] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
   
    const fetchData = async () => {
      try {
        const { readersList } = await service.fetchReaders();
 
        setReadersList(readersList);
  
        if (readersList && readersList.length > 0) {
          setValue(readersList[0].label);
          setValue((state) => {
          //console.log(state);  
          return state;
        });
        
          setReaderId(readersList[0].id);
        }
      } catch (error) {
        // Handle error
      }
    };
    fetchData();
    fetchIP();
  }, [value]);

  useEffect(() => {
   
    const capturePaymentAfterTimeout = async () => {
      // Wait for 6000 milliseconds before capturing payment
      await new Promise(resolve => setTimeout(resolve, 15000));
      await capturePayment();
    };

    // Listen for changes in the paymentIntent state
    if (paymentIntent) {
      capturePaymentAfterTimeout();
    }
  }, [paymentIntent]);

  const navigate = useNavigate();
  const transaction = async () => {
    navigate('/transactions');
  }

  const handleFormReset = () => {
    form.resetFields(); // Reset Ant Design form fields
    setName(''); // Reset state variables
    setAmount('');
    //setCurrency('');
    setDescr('');
    //setReaderId('');
  };

  

 
  
  const processPayment = async () => {
    try {
        // Validate form
    form
    .validateFields()
    .then(async () => {
      const formattedAmount = parseFloat(amount) * 100;

      setLoadingbtn(true);
      const result = await service.processPayment(formattedAmount, readerId,currency);
      const { error } = result;
      if(error){
        console.log(error.message);
        const message = error.message;
        openNotificationErr('error',message);
        setLoadingbtn(false);
        handleFormReset();
        addMessage(error.message);
        return;
      }

      openNotification('info');
      setReader(result.reader);
      setPaymentIntent(result.paymentIntent);
      addMessage(`Processing payment for ${formattedAmount} on reader ${result.reader.label}`);
      countDown();

    })
    .catch((error) => {
    
      console.error('Form validation error:', error);
    });
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  const capturePayment = async () => {
    try {
     
     
      const result = await service.capturePayment(paymentIntent.id, name,descr);
      const { error } = result;
      if (error) {
        addMessage(error.message);
        return;
      }
      setPaymentIntent(result.paymentIntent);
      
      addMessage(`Captured payment for ${result.paymentIntent.id}`);
      reset();
     
      setLoading(false);
      openNotificationWithIcon('success');
      setLoadingbtn(false);
      handleFormReset();
    } catch (error) {
      console.error('Error capturing payment:', error);
      modal.error({
        title: 'Capture Failed !',
        content: 'Try to capture the cart again please.',
        centered: true,
      });
    }
  };

  const fetchIP = async () => {
    try {
      const data = await service.fetchIP();
      //console.log(data);
      setIp(data);
    } catch (error) {
      console.error('Error fetching IP:', error);
    }
  };
 
  const simulatePayment = async () => {
    try {
      const result = await service.simulatePayment(reader.id);
      const { error } = result;
      if (error) {
        addMessage(error.message);
        return;
      }
      addMessage(`Simulating a customer tapping their card on simulated reader ${reader.id} for payment ${paymentIntent.id}`);
    } catch (error) {
      console.error('Error simulating payment:', error);
    }
  };

 

  const cancelAction = async () => {
    // Implement cancel action
  };



  const reset = () => {
    setPaymentIntent(null);
    setReader(null);
  };

  const addMessage = (message) => {
    setMessages((prevMessages) => service.addMessage(message, prevMessages));
  };

  const selectValidator = (rule, value) => {
    //console.log(currency);
    if(currency == null){
      return Promise.reject('Please select an currency');
    }else{
      return Promise.resolve();

  } 
  };

  const selectValidatorReader = (rule, value) => {
    if (!value) {
      return Promise.reject('Please select an reader');
    }
    return Promise.resolve();
  };


  
  const [form] = Form.useForm();

  return (
      <>
   {loading && (
  <Spin tip="Loading...">
    <Alert
      message="Transaction in progress"
      description="Please wait.."
      type="info"
    />
  </Spin>
)}

    <div className="sr-root">
      {contextHolder}
      {contextHolder2}
    
      <main className="sr-main">
      
        <h2>Make Payments with Stripe Terminal</h2>   

     
        <Form
      form={form}
         >        
    <br></br>



    {/* <label>Select Reader : </label>
          <select
  value={readerId || (readersList && readersList.length > 0 ? readersList[0].id : 'none')}
  onChange={(e) => setReaderId(e.target.value)}
  name="reader"
  id="reader-select"
  className="sr-select"
>
  <option value="none" disabled>
    Select a reader
  </option>
  {readersList &&
    readersList.map((r) => (
      <option key={r.id} value={r.id}>
        {r.label} ({r.id})
      </option>
    ))}
          </select> */}


{/* <label>Select Reader : </label>
<Form.Item
  name="reader"
  rules={[
    {
      validator: selectValidatorReader,
    },
  ]}
>

<Select
  placeholder="Select a reader"
  onChange={(value) => setReaderId(value)}
  defaultValue={readersList && readersList.length > 0 ? readersList[0].id : undefined}
  size='large'
>
  {readersList &&
    readersList.map((r) => (
      <Option key={r.id} value={r.id}>
        {r.label}
      </Option>
    ))}
</Select>





</Form.Item> */}

<label>Select Reader: </label>
          <select
            value={readerId || 'none'}
            onChange={(e) => setReaderId(e.target.value)}
            name="reader"
            id="reader-select"
            className="sr-select"
          >
            <option value="none" disabled>
              Select a reader
            </option>
            {readersList &&
              readersList.map((r) => (
                <option key={r.id} value={r.id}>
                  {r.label}
                </option>
              ))}
          </select>


    <section className="sr-form-row">
        <label htmlFor="name">Holder name :</label>
        <Form.Item
  name="name"
  rules={[{ required: true, message: 'Enter holder name!', }]}
>
  <Input
    id="name"
    className="sr-input"
    onChange={(e) => setName(e.target.value)}
    placeholder="Holder name"
    maxLength={50}
  />
</Form.Item>

        

    </section>

          

    <section className="sr-form-row">
    <label htmlFor="amount">Amount & Currency : </label>

    <Space wrap>
      <Form.Item
          name="amount"
          rules={[
            {
              required: true,
              message: 'Please enter the amount!',
            },
          ]}
        >
          <Input
            id="amount"
            value={amount || ''}
            className="sr-input"
            type="number" pattern="[0-9]"
            placeholder="Amount, e.g., $1.00"
            onChange={(e) => setAmount(e.target.value)}
            style={{
              width: 250,
            }}
          />
    </Form.Item>
    <Form.Item
  name="mySelect"
  rules={[
    {
      validator: selectValidator,
    },
  ]}
>
  <Select
    placeholder="Select a currency"
    id="currency"
    defaultValue={currency}
    onChange={(value) => setCurrency(value)}  // <-- Fixed
    style={{
      width: 245,
      
    }}
    size='large'
  >
    <Option value="EUR">EUR</Option>
    <Option value="USD">USD</Option>
    <Option value="GBP">GBP</Option>
    <Option value="DZD">DZD</Option>
    <Option value="TND">TND</Option>
  </Select>
    </Form.Item>
   
     </Space>       
     </section>
         
        
          <section class="sr-form-row">
          <label htmlFor="descr">Description :</label>
    <Form.Item
          name="descr"
          rules={[
            {
              required: true,
              message: 'Please enter your description!',
            },
          ]}
        >
            <Input
            id="descr"
            value={descr}
            className="sr-input"
            placeholder="Your description here"
            type="text"
            maxLength={80}
            onChange={(e) => setDescr(e.target.value)}
          />
    </Form.Item>
</section>
<br></br>
<Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        id="processbutton"
        onClick={processPayment}
        loading={loadingbtn}
      >
        {loadingbtn ? 'Processing...' : 'Start Payment'}
      </Button>
     
    </Form.Item>
          <section className="button-row">
          
            
            {/* <button
              type="button"
              id="capture-button"
              onClick={capturePayment}
              disabled={!isCapturable}
            >
              Capture
            </button> */}
          </section>
          {/* <section className="button-row">
            <button
              id="simulate-payment-button"
              onClick={simulatePayment}
              type="button"
              disabled={!isSimulateable}
            >
              Simulate Payment
            </button>
            <button onClick={cancelAction} id="cancel-button" type="button">
              Cancel
            </button>
          </section> */}

        <Button type="primary" id="transactionbutton" ghost
        onClick={transaction}
       >
        Last Transactions
        </Button>
        
          {/* <SrMessages messages={messages} /> */}
          </Form>
      </main>

    </div>
    </>
  );
}

export default Home;
