


const baseUrl = process.env.REACT_APP_PORT_SERVER;
const CircularJSON = require('circular-json');

const service = {

  fetchReaders : async () => {
    try {
      const response = await fetch(`${baseUrl}api/payment/readers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // You may need to include additional headers like authorization if required
        },
      });
  
     // console.log(await response.json());
  
      if (!response.ok) {
        throw new Error(`Failed to fetch readers: ${response.statusText}`);
      }
  
      const data = await response.json();
    //  console.log(data.data);
      return data.data;
    } catch (error) {
      console.error('Error fetching readers:', error);
      throw error;
    }
  },

  fetchTransactions : async () => {
    try {
      const response = await fetch(`${baseUrl}api/payment/lastransactions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
     // console.log(await response.json());
  
      if (!response.ok) {
        throw new Error(`Failed to fetch readers: ${response.statusText}`);
      }
  
      const data = await response.json();
      //console.log(data.data.transaction);
      return data;
    } catch (error) {
      console.error('Error fetching readers:', error);
      throw error;
    }
  },

  processPayment: async (amount, readerId, currency) => {
    try {
        // Create an object with the data you want to send
        const requestData = { amount, readerId, currency };
        //console.log(amount);
        // Convert the data to a string, handling circular references
        const body = CircularJSON.stringify(requestData);
        //console.log(body);

        const response = await fetch(`${baseUrl}api/payment/process`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body,
        });

        // Check if the response is ok before trying to read the body
        if (!response.ok) {
            throw new Error(`Error processing payment: ${response.statusText}`);
        }

        // Read the response body once and store it in a variable
        const responseData = await response.json();
        //console.log(responseData);

        return responseData;
    } catch (error) {
        console.error('Error processing payment:', error);
        throw error;
    }
},


  fetchIP: async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      if (!response.ok) {
        throw new Error(`Error fetching IP: ${response.statusText}`);
      }
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error fetching IP:', error);
      throw error;
    }
  },

  simulatePayment: async (readerId) => {
    try {
      const response = await fetch(`${baseUrl}readers/simulate-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ readerId }),
      });
      if (!response.ok) {
        throw new Error(`Error simulating payment: ${response.statusText}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error simulating payment:', error);
      throw error;
    }
  },

  capturePayment: async (paymentIntentId, name,descr) => {
    try {
      const response = await fetch(`${baseUrl}api/payment/capture`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paymentIntentId, name,descr }),
      });
      if (!response.ok) {
        throw new Error(`Error capturing payment: ${response.statusText}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error capturing payment:', error);
      throw error;
    }
  },

  addMessage: (message, prevMessages) => [...prevMessages, message],

};

export default service;
