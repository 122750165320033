import React from 'react';
import Home from './Home';
import Transaction from './transactions/transaction';
import { Routes, Route } from "react-router-dom";

function App() {
 
  return (
      <>
  <Routes>
    <Route exact path="/" element={<Home />} />
    <Route exact path="/transactions" element={<Transaction />} />      
  </Routes>
    </>
  );
}

export default App;
