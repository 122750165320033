import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import styled from 'styled-components'; // Import styled-components
import service from '../service';
import moment from 'moment';

function Transaction() {
    const [transactionList, setTransactionsList] = useState([]);

    const fetchData = async () => {
        try {
            const { data } = await service.fetchTransactions();
            setTransactionsList(data.transaction);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Define a styled component for successful status
    const SuccessStatus = styled.td`
    background-color: rgb(80, 200, 120);
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    `;

    const FailedStatus = styled.td`
    background-color: rgb(220, 20, 60);
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    `;

    const currencyFormat = (value, currency) => {
        if (isNaN(value) || value === null || value === undefined) {
            return null
        }
    
        const floatValue = parseFloat(value)
    
        switch (currency) {
            case 'DZD':
                return Math.ceil(floatValue)
            case 'TND':
                return floatValue.toFixed(3)
            case 'GBP':
                return floatValue.toFixed(2)
            default:
                return floatValue.toFixed(2)
        }
    };
    

    const columns = [
      
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
            width: '10%',
        },
        {
            title: 'Date',
            dataIndex: 'addDate',
            key: 'addDate',
            width: '20%',
            render: (addDate) => {
                return moment(addDate).format('DD/MM/YYYY HH:mm:ss');
            },
        },
        {
            title: 'Holder Name',
            dataIndex: 'cardholderName',
            key: 'cardholderName',
            width: '10%',
        },
        {
            title: 'Amount',
            key: 'amountCurrency',
            width: '10%',
            render: (text, record) => (
                <span>{currencyFormat(record.amount, record.currency.toUpperCase())} {record.currency.toUpperCase()}</span>
            ),
        },
        
        {
            title: 'Description',
            dataIndex: 'note',
            key: 'note',
            width: '20%',
        },
        {
            title: 'Status',
            dataIndex: 'statusId',
            key: 'statusId',
            width: '10%',
            render: (statusId) => {
                return statusId === 1 ? <SuccessStatus>Successful</SuccessStatus> : statusId === 2 ? <FailedStatus>Failed</FailedStatus> : '';
            }
        },
        {
            title: 'IP Address',
            dataIndex: 'IP',
            key: 'IP',
            width: '15%',
        },
     
    ];

    return (
        <div style={{ margin: '5%' }}>
            <div style={{ margin: '0 auto', maxWidth: '98%' }}>
                <center><h2>List Transactions</h2></center>
                <br></br>
                <br></br>
                <Table
                    columns={columns}
                    dataSource={transactionList}
                    pagination={false}
                    bordered
                    className="custom-table-header"
                />
            </div>
        </div>
    );
}

export default Transaction;
